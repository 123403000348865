@import "../../config";
@import "../../mixins/typography";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: map-get($map: $spacing, $key: borderRadius);
}

.author-img {
  object-fit: cover;
  border-radius: 50%;
}

.name {
  @include type($size: base, $font: copy, $color: copyDark);
  text-align: center;
  margin-top: map-get($map: $spacing, $key: sitePadding);
}